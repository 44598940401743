import _styled from "styled-components";
import { DateTime } from 'luxon';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa';
import NoSSR from 'react-no-ssr';
import profilePic from '../../../../public/static/picture.png';
import { PostData } from '../../../lib/Posts';
import Layout from '../../Layout';
const Hero = _styled.section.withConfig({
  displayName: "Homepage__Hero",
  componentId: "sc-1nb7vz7-0"
})({
  "textAlign": "center"
});
const Avatar = _styled.div.withConfig({
  displayName: "Homepage__Avatar",
  componentId: "sc-1nb7vz7-1"
})({
  "marginBottom": "1.5rem",
  "marginTop": "3rem",
  "display": "inline-block",
  "height": "8rem",
  "width": "8rem",
  "overflow": "hidden",
  "borderRadius": "9999px"
});
const HeroTitle = _styled.h1.withConfig({
  displayName: "Homepage__HeroTitle",
  componentId: "sc-1nb7vz7-2"
})({
  "fontSize": "1.875rem",
  "lineHeight": "2.25rem",
  "fontWeight": "800",
  "letterSpacing": "-0.025em",
  "--tw-text-opacity": "1",
  "color": "rgb(17 24 39 / var(--tw-text-opacity))",
  "@media (min-width: 640px)": {
    "fontSize": "2.25rem",
    "lineHeight": "2.5rem"
  }
});
const HeroDescription = _styled.h2.withConfig({
  displayName: "Homepage__HeroDescription",
  componentId: "sc-1nb7vz7-3"
})({
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "1rem",
  "maxWidth": "42rem",
  "fontSize": "1.25rem",
  "lineHeight": "1.75rem",
  "--tw-text-opacity": "1",
  "color": "rgb(107 114 128 / var(--tw-text-opacity))"
});
const SocialIcons = _styled.section.withConfig({
  displayName: "Homepage__SocialIcons",
  componentId: "sc-1nb7vz7-4"
})({
  "marginTop": "1rem",
  "textAlign": "center"
});
const SocialIcon = _styled.a.withConfig({
  displayName: "Homepage__SocialIcon",
  componentId: "sc-1nb7vz7-5"
})({
  "display": "inline-block",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "--tw-text-opacity": "1",
  "color": "rgb(55 65 81 / var(--tw-text-opacity))",
  "&:hover": {
    "--tw-text-opacity": "1",
    "color": "rgb(79 70 229 / var(--tw-text-opacity))"
  }
});
const List = _styled.section.withConfig({
  displayName: "Homepage__List",
  componentId: "sc-1nb7vz7-6"
})({
  "marginTop": "2rem",
  "marginBottom": "2rem"
});
const Pagination = _styled.section.withConfig({
  displayName: "Homepage__Pagination",
  componentId: "sc-1nb7vz7-7"
})({
  "height": "2.5rem",
  "fontSize": "1rem",
  "lineHeight": "1.5rem",
  "--tw-text-opacity": "1",
  "color": "rgb(55 65 81 / var(--tw-text-opacity))"
});
const Next = _styled.section.withConfig({
  displayName: "Homepage__Next",
  componentId: "sc-1nb7vz7-8"
})({
  "float": "right",
  "&:hover": {
    "--tw-text-opacity": "1",
    "color": "rgb(79 70 229 / var(--tw-text-opacity))"
  }
});
const Previous = _styled.section.withConfig({
  displayName: "Homepage__Previous",
  componentId: "sc-1nb7vz7-9"
})({
  "float": "left",
  "&:hover": {
    "--tw-text-opacity": "1",
    "color": "rgb(79 70 229 / var(--tw-text-opacity))"
  }
});
const Item = _styled.article.withConfig({
  displayName: "Homepage__Item",
  componentId: "sc-1nb7vz7-10"
})({
  "marginTop": "2rem",
  "borderTopWidth": "2px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(243 244 246 / var(--tw-border-opacity))",
  "paddingTop": "2rem",
  "&:hover": {
    "cursor": "pointer"
  }
});
const Title = _styled.h1.withConfig({
  displayName: "Homepage__Title",
  componentId: "sc-1nb7vz7-11"
})({
  "marginTop": "0.5rem",
  "fontSize": "1.25rem",
  "lineHeight": "1.75rem",
  "fontWeight": "600",
  "--tw-text-opacity": "1",
  "color": "rgb(17 24 39 / var(--tw-text-opacity))",
  "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms",
  ".group:hover &": {
    "--tw-text-opacity": "1",
    "color": "rgb(79 70 229 / var(--tw-text-opacity))"
  }
});
const Excerpt = _styled.p.withConfig({
  displayName: "Homepage__Excerpt",
  componentId: "sc-1nb7vz7-12"
})({
  "marginTop": "0.75rem",
  "fontSize": "1rem",
  "lineHeight": "1.5rem",
  "--tw-text-opacity": "1",
  "color": "rgb(107 114 128 / var(--tw-text-opacity))"
});
const Date = _styled.time.withConfig({
  displayName: "Homepage__Date",
  componentId: "sc-1nb7vz7-13"
})({
  "display": "block",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem",
  "--tw-text-opacity": "1",
  "color": "rgb(107 114 128 / var(--tw-text-opacity))"
});
type Props = {
  posts: PostData[];
  page: number;
  pages: number;
};
export default function Homepage({
  posts,
  page,
  pages
}: Props) {
  return <Layout>
            <Head>
                <title>Jens Segers</title>
            </Head>
            <Layout.Content>
                <Hero>
                    <Avatar>
                        <Image src={profilePic} alt="Jens Segers" width="256" height="256" />
                    </Avatar>

                    <HeroTitle>Jens Segers</HeroTitle>
                    <HeroDescription>
                        Full stack developer with a passion for product
                        development & technology. Engineering manager and
                        Solution Architect at{' '}
                        <a href="https://mylighthouse.com" target="_blank" rel="noreferrer">
                            Lighthouse
                        </a>
                        .
                    </HeroDescription>
                    <SocialIcons>
                        <SocialIcon href="https://github.com/jenssegers" rel="noreferrer" target="_blank">
                            <FaGithub />
                        </SocialIcon>
                        <SocialIcon href="https://twitter.com/jenssegers" rel="noreferrer" target="_blank">
                            <FaTwitter />
                        </SocialIcon>
                        <SocialIcon href="https://www.linkedin.com/in/jenssegers" rel="noreferrer" target="_blank">
                            <FaLinkedin />
                        </SocialIcon>
                    </SocialIcons>
                </Hero>
                <List>
                    {posts && posts.map(post => <Item key={post.slug} className={`h-entry group`}>
                                <Link href={`/${post.slug}`} className="u-url">
                                    <Date className={`dt-published`}>
                                        <NoSSR>
                                            {DateTime.fromISO(post.date).toLocaleString(DateTime.DATE_FULL)}
                                        </NoSSR>
                                    </Date>

                                    <Title className={`p-name`}>
                                        {post.title}
                                    </Title>
                                    <Excerpt className={`p-summary`}>
                                        {post.excerpt}
                                    </Excerpt>
                                </Link>
                            </Item>)}
                </List>
                <Pagination>
                    {page > 1 && <Previous>
                            <Link href={`/archive/${page - 1}`}>
                                « Previous
                            </Link>
                        </Previous>}
                    {page < pages && <Next>
                            <Link href={`/archive/${page + 1}`}>Next »</Link>
                        </Next>}
                </Pagination>
            </Layout.Content>
        </Layout>;
}