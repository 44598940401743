import { GetStaticProps } from 'next';
import React from 'react';
import Homepage from '../components/Pages/Homepage';
import Posts from '../lib/Posts';
export default function Page({
  posts,
  maxPage
}) {
  return <Homepage posts={posts} page={1} pages={maxPage} />;
}
export const getStaticProps: GetStaticProps = async () => {
  const posts = Posts.all();
  return {
    props: {
      posts: posts.slice(0, 15),
      maxPage: Math.ceil(posts.length / 15)
    }
  };
};